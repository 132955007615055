import Axios from "../Axios";

const project = {
    create(data) {
        return Axios.post('/project', data)
    },
    read(limit = 10, page = 1) {
        return Axios.get('/project?active=1&limit=' + limit + "&page=" + page)
    },
    readOne(id) {
        return Axios.get('/project/' + id)
    },
    update(id, data) {
        return Axios.put('/project/' + id, data)
    },
    delete(id) {
        return Axios.put('/project/' + id, {
            "active": false
        })
    },
    createTask(data) {
        return Axios.post('/task', data)
    },
    readTask(project, limit = 10, page = 1) {
        return Axios.get('/task?active=1&project=' + project + '&limit=' + limit + "&page=" + page)
    },
    updateTask(id, data) {
        return Axios.put('/task/' + id, data)
    },
    deleteTask(id) {
        return Axios.put('/task/' + id, {
            "active": false
        })
    },
}

export default project;
