import Axios from "../Axios";

const report = {
    payment(data) {
        return Axios.post('/report/payment', data)
    },
    expense(data) {
        return Axios.post('/report/expense', data)
    },
}

export default report;
