import React from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import AddIcon from '@material-ui/icons/Add';
import {Link} from "react-router-dom";
import DataTable from "../../composents/DataTable";
import resources from "../../services";

function UserPage() {
    return (<div>
        <Header/>
        <div className="container-fluid">
            <div className="row">
                <Sidebar/>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                    <div>
                        <h3>Utilisateurs</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link className="text-muted"
                                                                          to="/dashboard">Acceuil</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Utilisateurs</li>
                                </ol>
                            </nav>
                            <Link className="btn btn-secondary btn-sm" to="/user/create"><AddIcon/> Nouvel
                                utilisateur</Link>
                        </div>
                    </div>


                    <div className="col mt-5">
                        <DataTable column={[
                            {label: "Nom complet", field: "fullName",},
                            {label: "Email", field: "email"},
                            {label: "Numéro de téleqphone", field: "phone"},
                            {label: "Date", field: "createdAt"},
                        ]}
                                   element={"user"}
                                   resource={resources.user}
                                   action={()=>{}}
                                   update
                                   deleteItem


                        />
                    </div>
                </main>
            </div>
        </div>

    </div>)
}

export default UserPage
