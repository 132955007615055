import Axios from "../Axios";

const tax = {
    create(data) {
        return Axios.post('/tax', data)
    },
    read(limit = 10, page = 1) {
        return Axios.get('/tax?active=1&limit=' + limit + "&page=" + page)
    },
    readOne(id) {
        return Axios.get('/tax/' + id)
    },
    update(id, data) {
        return Axios.put('/tax/' + id, data)
    },
    delete(id) {
        return Axios.put('/tax/' + id, {
            "active": false
        })
    }
}

export default tax;
