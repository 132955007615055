import Axios from "../Axios";

const payment = {
    create(data) {
        return Axios.post('/payment', data)
    },
    read(limit = 10, page = 1) {
        return Axios.get('/payment?active=1&limit=' + limit + "&page=" + page)
    },
    readOne(id) {
        return Axios.get('/payment/' + id)
    },
    update(id, data) {
        return Axios.put('/payment/' + id, data)
    },
    delete(id) {
        return Axios.put('/payment/' + id, {
            "active": false
        })
    },
}

export default payment;
