import React, {useState, useEffect} from "react"
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from 'react-select'
import resources from "../services";
import {DialogActions} from "@material-ui/core";

export default function AddTax({isOpen, handleClose, change}) {
    const [data, setData] = useState([])
    const [item, setItem] = useState([]);

    function onChange(e) {
        setItem(e)
    }

    async function onGet() {
        let res = await resources.tax.read()
        if (res.status === 200) {
            let a = []
            res.data.items.forEach((el, key) => {
                a.push({
                    label: el.name + "(" + el.value + "%)",
                    value: el.id,
                    amount: el.value
                })
            })
            setData(a)
        }
    }

    useEffect(() => {
        onGet()
    }, [])
    return (

        <Dialog

            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Taxes"}</DialogTitle>
            <DialogContent style={{
                width: 300,
                height: 300,
            }}>
                <DialogContentText id="alert-dialog-description">
                    <Select options={data} isMulti onChange={onChange}/>
                </DialogContentText>
            </DialogContent>
            <DialogActions>

                <button onClick={() => change(item)} className="btn btn-app-default btn-sm">
                    Enregistré
                </button>
            </DialogActions>

        </Dialog>

    )
}