import React from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import AddIcon from '@material-ui/icons/Add';
import {Link} from "react-router-dom";
import DataTable from "../../composents/DataTable";
import resources from "../../services";

function PayementPage() {
    return (<div>
        <Header/>
        <div className="container-fluid">
            <div className="row">
                <Sidebar/>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                    <div>
                        <h3>Dépenses</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link className="text-muted"
                                                                          to="/dashboard">Acceuil</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Dépenses</li>
                                </ol>
                            </nav>
                            <Link className="btn btn-app-default btn-sm" to="/expense/create"><AddIcon/> Nouvelle dépense</Link>
                        </div>
                    </div>

                    <div className="col mt-5">
                        <DataTable column={[
                            {label: "Catégorie", field: "category", render: ["name"]},
                            {label: "Client", field: "customer", render: ["fullName"]},
                            {label: "Montant", field: "amount"},
                            {label: "Notes", field: "note"},
                            {label: "Date", field: "dateExpense"},
                        ]}
                                   element={"expense"}
                                   action={()=>{}}
                                   deleteItem
                                   resource={resources.expense}


                        />
                    </div>
                </main>
            </div>
        </div>

    </div>)
}

export default PayementPage
