import React, {useState, useEffect} from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import AddIcon from '@material-ui/icons/Add';
import {Link} from "react-router-dom";
import DataTable from "../../composents/DataTable";
import resources from "../../services";

function ArticlePage() {
    return (<div>
        <Header/>
        <div className="container-fluid">
            <div className="row">
                <Sidebar/>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                    <div>
                        <h3>Articles</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item "><Link className="text-muted"
                                                                           to="/dashboard">Acceuil</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Articles</li>
                                </ol>
                            </nav>
                            <Link className="btn btn-app-default btn-sm" to="/article/create"><AddIcon/> Nouvelle article</Link>
                        </div>
                    </div>

                    <div className="col mt-5">
                        <DataTable column={[
                            {label: "Nom", field: "name"},
                            {label: "Prix", field: "price"},
                        ]}
                                   element={"article"}
                                   resource={resources.article}
                                   action={()=>{}}
                                   update
                                   deleteItem/>
                    </div>
                </main>
            </div>
        </div>

    </div>)
}

export default ArticlePage
