import React, {useEffect, createContext, useContext} from "react";
import "./alert.css";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const AlertContext = createContext(null);

export function useAlert() {
    return useContext(AlertContext);
}


function AlertMessage(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const Alert = ({children}) => {
    const [message, setMessage] = React.useState({
        success: false,
        error: false,
    });

    function pushMessage(params) {
        setMessage(params);
        // window.$("#element").toast("show");
    }

    const state = {
        push: pushMessage,
    };

    return (
        <AlertContext.Provider value={state}>
            <div>
                {children}
                <Snackbar open={message.success} autoHideDuration={6000}
                          anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                    <AlertMessage onClose={() => setMessage({success: false})} severity="success">
                        Opération effectué avec succès
                    </AlertMessage>
                </Snackbar>

                <Snackbar open={message.error} autoHideDuration={1000}
                          anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                    <AlertMessage onClose={() => setMessage({error: false})} severity="error">
                        Une erreur c'est produite
                    </AlertMessage>
                </Snackbar>
            </div>
        </AlertContext.Provider>
    );
};

;
