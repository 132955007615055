import * as React from "react";
import * as ReactDom from "react-dom";
import App from "./App";


function render() {
    ReactDom.render(<App/>, document.getElementById("root"))
}

render();
