import React, {useState, useEffect, useRef} from "react"
import config from "../../constants";
import ReactToPrint from "react-to-print";
import Btn from "../../ui/Btn";
import PrintIcon from "@material-ui/icons/Print";

export default function Sale({data, from, to}) {
    const [total, setTotal] = useState(0), componentRef = useRef();
    useEffect(() => {
        let d = data.reduce(
            (accumulator, currentValue) =>
                accumulator + parseInt(currentValue.amount),
            0,
        );
        setTotal(d);
    }, [data])

    return (
        <div>
            <div className="d-flex justify-content-between">
                <div/>

                <ReactToPrint
                    trigger={() => {
                        return (
                            <Btn
                                className="btn btn-app-default btn-sm"
                            >
                                <PrintIcon/> Imprimer
                            </Btn>
                        );
                    }}
                    content={() => componentRef.current}
                />
            </div>

            <div className="card mt-1" ref={componentRef}>
                <div className="card-body">
                    <div className="d-flex justify-content-between">
                        <h3 className="fw-bold">{config.app.name}</h3>
                        <p className="text-muted">{from} / {to}</p>
                    </div>

                    <h6 className="fw-bold text-center mb-5">Rapport de vente</h6>

                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Client</th>
                            <th scope="col">Facture</th>
                            <th scope="col">Montant</th>
                            <th scope="col">Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            data && data.map((el, key) => {
                                return <tr>
                                    <th scope="row" key={key}>{el.id}</th>
                                    <td>{el?.customer?.fullName}</td>
                                    <td>{el?.invoice?.id}</td>
                                    <td>{el?.amount} $</td>
                                    <td>{el?.createdAt}</td>
                                </tr>
                            })
                        }

                        </tbody>

                    </table>
                    <div className="d-flex justify-content-between bg-info p-2 align-content-center align-items-center">
                        <h6>TOTAL</h6>
                        <h6 className="text-end fw-bold">{total} $</h6>
                    </div>
                </div>
            </div>
        </div>
    )


}