import React, { useState, useEffect } from "react"
import config from "../../constants";
import DeleteIcon from "@material-ui/icons/Delete";

export default function Facture1({ data, items, taxes }) {
    const date = new Date()

    return (
        <div className="card border-0 ">
            <div className="card-body">
                <div>
                    <img alt=" " src={config.server.web + "/uploads/assets/logo.jpg"} width="220" />
                </div>
                <div>
                    <h1 className="fw-bold text-center mt-5 mb-5 color-app-default">FACTURE</h1>

                    <h6 className="fw-bold text-center">R.C.C.M: LSHI/RCCM/21-B-01548.</h6>
                    <h6 className="fw-bold text-center">ID NAT: 05-M6901-N94636Z.</h6>
                    <h6 className="fw-bold text-center">N Impot: A2281844L</h6>
                    <h6 className="fw-bold text-center">38, Av. Mwanza Seya, Quartier Plateau Karavia - Commune de
                        Annexe</h6>
                    <hr />
                </div>

                <div className="d-flex justify-content-between">
                    <div>
                        <h6> {data?.customer?.fullName}</h6>
                        <p className="text-muted col-lg-5">{data?.customer?.address}{data?.customer?.address1}{data?.customer?.city}{data?.customer?.country}</p>
                    </div>
                    <div>
                        <div className="d-flex justify-content-between">
                            <p className="text-muted">Numéro: </p>
                            <h5> {data.id}/....../{date.getFullYear()}</h5>
                        </div>

                        <div className="d-flex justify-content-between">
                            <p className="text-muted">Date de facturation : </p>
                            <h5> {data.createdAt}</h5>
                        </div>

                        <div className="d-flex justify-content-between">
                            <p className="text-muted">Date de limite : </p>
                            <h5> {data.dueDate}</h5>
                        </div>

                    </div>

                </div>

                <div className="mt-3">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Article</th>
                                <th scope="col">Prix</th>
                                <th scope="col">Quantité</th>
                                <th scope="col">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data && items.map((el, key) => {
                                    return <tr key={key}>
                                        <td>{el.item}</td>
                                        <td>{el.price} $</td>
                                        <td>{el.qty}</td>
                                        <td>{el.total} $</td>
                                    </tr>
                                })
                            }


                        </tbody>
                    </table>
                </div>

                <div className="row mt-5">
                    <div className="col">

                    </div>
                    <div className="col">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td colSpan="2">Sous-total</td>
                                    <td className="text-end">  {
                                        items.reduce(function (accumulator, valourCourant, index, array) {
                                            return accumulator + parseInt(valourCourant.total)
                                        }, 0)
                                    } $
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2">Remise(%)</td>
                                    <td className="text-end">{data.discount}</td>
                                </tr>
                                {
                                    data && taxes.map((el, key) => {
                                        return (
                                            <tr>
                                                <td colSpan="2">{el.tax}({el.value} %)</td>
                                                <td className="text-end"> {el.amount} $
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <td colSpan="2" className="fw-bold"><h5>TOTAL</h5></td>
                                    <td className="text-end fw-bold">
                                        <h5>{data.total} $</h5>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


        </div>
    )
}