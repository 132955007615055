import React, {useState, useEffect} from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import SaveIcon from '@material-ui/icons/Save';
import {Link} from "react-router-dom";
import {useAlert} from "../../ui/Alert";
import resources from "../../services";
import Btn from "../../ui/Btn";
import Select from "react-select";

function CreateProject() {
    const [loader, setLoader] = useState(false), {push} = useAlert(),
        [customers, setCustomers] = useState([]),
        [users, setUsers] = useState([]),
        [team, setTeam] = useState([]),
        [lead, setLead] = useState(),
        [category, setCategory] = useState(),
        [customer, setCustomer] = useState()

    async function onGet() {
        let pro = await resources.user.read()
        if (pro.status === 200) {
            let a = []
            pro.data.items.forEach((el) => {
                if (el.status !== 2) {
                    a.push({
                        value: el.id,
                        label: el.fullName,
                    })
                }

            })
            setUsers(a)
        }


        let cus = await resources.customer.read()
        if (cus.status === 200) {
            let a = []
            cus.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.fullName,
                })
            })
            setCustomers(a)
        }
    }

    useEffect(() => {
        onGet();
    }, [])

    /**
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    async function onCreate(e) {
        e.preventDefault()
        setLoader(true)
        let object = {
            name: e.target.name.value,
            beginDate: e.target.beginDate.value,
            endDate: e.target.endDate.value,
            note: e.target.note.value,
        };
        if (lead) {
            object.lead = lead
        }
        if (customer) {
            object.customer = customer
        }
        if (team) {
            let a = []
            team.forEach((el, key) => {
                a.push(el.value)
            })
            object.team = a
        }

        let res = await resources.project.create(object)
        if (res.status === 201) {
            push({
                success: true
            })
          
        } else {
            push({
                error: true
            })
        }

        setLoader(false)
    }

    return (<div>
        <Header/>
        <div className="container-fluid">
            <div className="row">
                <Sidebar/>
                <form onSubmit={onCreate}>


                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                        <div>
                            <h3>Nouveau projet</h3>
                            <div className="d-flex justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link className="text-muted"
                                                                              to="/dashboard">Acceuil</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page"><Link
                                            className="text-muted" to="/projects">Projects</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Nouveau project</li>
                                    </ol>
                                </nav>
                                <Btn className="btn btn-app-default btn-sm"
                                     type="submit"><SaveIcon/> Enregistré</Btn>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-3 mb-3">
                            <div className="col-lg-6 ">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Nom <span
                                                style={{color: "red"}}>*</span></label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                   required name="name"/>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Date de
                                                debut <span
                                                    style={{color: "red"}}>*</span></label>
                                            <input type="date" className="form-control" id="exampleInputEmail1"
                                                   name="beginDate" required/>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Date de fin <span
                                                style={{color: "red"}}>*</span></label>
                                            <input type="date" className="form-control" id="exampleInputEmail1"
                                                   name="endDate" required/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Chef de
                                                projet</label>
                                            <Select onChange={(e) => setLead(e.value)} options={users}/>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Client</label>
                                            <Select onChange={(e) => setCustomer(e.value)} options={customers}/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Equipe</label>
                                            <Select onChange={setTeam} isMulti options={users}/>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlTextarea1"
                                                   className="form-label">Note</label>
                                            <textarea className="form-control" id="exampleFormControlTextarea1"
                                                      rows="3" name="note"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </form>
            </div>
        </div>

    </div>)
}

export default CreateProject
