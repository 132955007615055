import React, {useState, useEffect} from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import AddIcon from '@material-ui/icons/Add';
import {Link, useParams} from "react-router-dom";
import resources from "../../services";
import File from "../../composents/File";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Btn from "../../ui/Btn";

function ViewArchive() {
    const [data, setData] = useState([]),
        [open, setOpen] = React.useState(false),
        [loader, setLoader] = React.useState(false),
        {id} = useParams()

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function onGet() {
        let res = await resources.archive.files(id)
        if (res.status === 200) setData(res.data.items)
    }

    async function onCreate(e) {
        e.preventDefault()
        setLoader(true)
        let formData = new FormData(e.target)
        formData.append("folder", id)
        let res = await resources.archive.createFile(formData)
        if (res.status === 201) {
            onGet()
            setLoader(false)
            setOpen(false)
        } else {
            setLoader(false)
        }
        setLoader(false)
    }

    useEffect(() => {
        onGet();
    }, [])

    return (<div>
        <Header/>
        <div className="container-fluid">
            <div className="row">
                <Sidebar/>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                    <div>
                        <h3>Archive {id}</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link className="text-muted"
                                                                          to="/dashboard">Acceuil</Link></li>
                                    <li className="breadcrumb-item"><Link className="text-muted"
                                                                          to="/archives">Archives</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Archive {id}</li>
                                </ol>
                            </nav>
                            <button className="btn btn-app-default btn-sm" onClick={handleClickOpen}><AddIcon/> Nouveau
                                ficchier
                            </button>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <form onSubmit={onCreate}>
                                    <DialogTitle
                                        id="alert-dialog-title">{"Nouveau fichier"}</DialogTitle>
                                    <DialogContent>
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Nom <span
                                                style={{color: "red"}}>*</span></label>
                                            <input type="text" className="form-control" id="exampleFormControlInput1"
                                                   name="name"/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1"
                                                   className="form-label">Fichier <span
                                                style={{color: "red"}}>*</span></label>
                                            <input type="file" className="form-control" id="exampleFormControlInput1"
                                                   name="sourceFile"/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlTextarea1"
                                                   className="form-label">Description</label>
                                            <textarea className="form-control" id="exampleFormControlTextarea1"
                                                      rows="3" name="note"></textarea>
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <button onClick={handleClose} type="button" className="btn btn-danger">
                                            Annuler
                                        </button>
                                        <Btn onLoad={loader} type="submit" className="btn btn-secondary">
                                            Créer
                                        </Btn>
                                    </DialogActions>
                                </form>
                            </Dialog>
                        </div>
                    </div>

                    <div className="row mt-5">

                        {
                            data && data.map((el, key) => {
                                return <File id={el.id} name={el.name} note={el.note} onGet={onGet} src={el.sourceUrl}/>
                            })
                        }
                    </div>

                </main>
            </div>
        </div>

    </div>)
}

export default ViewArchive
