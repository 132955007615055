import React, {useState, useEffect} from "react"
import FolderIcon from '@material-ui/icons/Folder';
import {Link} from "react-router-dom";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Btn from "../ui/Btn";
import resources from "../services";

export default function Archive({id, name, note, onGet}) {
    const [open, setOpen] = React.useState(false);
    const [loader, setLoader] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function onUpdate(e) {
        e.preventDefault()
        setLoader(true)
        let objet = {
            name: e.target.name.value,
            note: e.target.note.value
        }
        let res = await resources.archive.update(id, objet)
        if (res.status === 201) {
            onGet()
            setLoader(false)
            setOpen(false)
        } else {
            setLoader(false)
        }
        setLoader(false)
    }

    async function onDelete() {
        let res = await resources.archive.delete(id)
        if (res.status === 201) {
            onGet()

        }
    }

    return (
        <div className="col-lg-3 mb-3">

            <div className="card">
                <Link className="text-muted" to={"/archive/view/" + id}>
                    <div className="card-body">
                        <p className="text-center"><FolderIcon style={{fontSize: 150}}/></p>

                    </div>
                </Link>
                <div className="card-footer bg-white">
                    <div className="d-flex justify-content-between">
                        <p>{name}</p>
                        <div className="dropdown">
                            <MoreVertIcon id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                          aria-expanded="false" style={{cursor: "pointer"}}/>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><span className="dropdown-item" onClick={handleClickOpen}>
                                        <EditIcon/> Modifier</span>
                                </li>

                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <form onSubmit={onUpdate}>
                                        <DialogTitle
                                            id="alert-dialog-title">{"Nouveau classeur"}</DialogTitle>
                                        <DialogContent>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput1"
                                                       className="form-label">Nom <span
                                                    style={{color: "red"}}>*</span></label>
                                                <input type="text" className="form-control"
                                                       id="exampleFormControlInput1"
                                                       name="name" defaultValue={name}/>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlTextarea1"
                                                       className="form-label">Description</label>
                                                <textarea className="form-control" id="exampleFormControlTextarea1"
                                                          rows="3" name="note" defaultValue={note}></textarea>
                                            </div>
                                        </DialogContent>
                                        <DialogActions>
                                            <button onClick={handleClose} className="btn btn-danger">
                                                Annuler
                                            </button>
                                            <Btn onLoad={loader} type="submit" className="btn btn-secondary">
                                                Enrégistré
                                            </Btn>
                                        </DialogActions>
                                    </form>
                                </Dialog>

                                <li><span className="dropdown-item" onClick={onDelete}>
                                        <DeleteIcon/> Supprimer</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}