import React, {useState, useEffect} from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import AssignmentIcon from '@material-ui/icons/Assignment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import {Link, useParams} from "react-router-dom";
import ViewCategory from "./ViewCategory";
import ViewTax from "./ViewTax";

function SettingPage() {
    const {action} = useParams();
    return (<div>
        <Header/>
        <div className="container-fluid">
            <div className="row">
                <Sidebar/>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                    <div>
                        <h3>Réglages</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link className="text-muted"
                                                                          to="/dashboard">Acceuil</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Reglages</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-3">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Link className="nav-link text-muted" aria-current="page"
                                          to="/settings/category"><AssignmentIcon/> Catégorie des
                                        dépenses</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link text-muted" aria-current="page"
                                          to="/settings/tax"><MonetizationOnIcon/>Taxes</Link>
                                </li>
                            </ul>
                        </div>
                        {action === "category" && <ViewCategory/>}
                        {action === "tax" && <ViewTax/>}

                    </div>


                </main>
            </div>
        </div>

    </div>)
}

export default SettingPage
