import React, {useState} from "react"
import Btn from "../../ui/Btn";
import resources from "../../services";
import config from "../../constants";

export default function LoginPage(key) {
    const [loader, setLoader] = useState(false), [error, setError] = useState(false)

    async function auth(e) {
        e.preventDefault()
        setLoader(true)
        let fromData = new FormData(e.target)
        let res = await resources.auth.auth(fromData)
        if (res.status === 200) {
            if (res.data.result) {
                localStorage.setItem("userId", res.data.value.id)
                localStorage.setItem("userName", res.data.value.fullName)
                localStorage.setItem("user", JSON.stringify(res.data.value))
                window.location.replace("/dashboard")
                setLoader(false)
            } else {
                setLoader(false)
                setError(true)
            }
        }

    }

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-lg-4">
                    <div className="mb-5">
                        <h1 className="fw-bold text-center color-app-default">{config.app.name}</h1>

                    </div>

                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={auth}>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Adresse e-mail</label>
                                    <input type="email" className="form-control" id="exampleInputEmail1"
                                           name="email" required/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputPassword1" className="form-label">Mot de passe</label>
                                    <input type="password" required className="form-control" id="exampleInputPassword1"
                                           name="password"/>
                                </div>
                                <Btn type="submit" onLoad={loader} className="btn btn-app-default w-100">Connexion</Btn>
                            </form>

                        </div>
                    </div>
                    {
                        error && (<div className="alert alert-danger alert-dismissible fade show mt-3">
                            <strong>Adresse e-mail ou mot de passe incorrect!</strong>
                            <button type="button" className="btn-close" onClick={() => setError(false)}></button>
                        </div>)
                    }

                </div>
            </div>

        </div>
    )
}