import React from "react"
import DataTable from "../../composents/DataTable";
import resources from "../../services";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Btn from "../../ui/Btn";

export default function ViewCategory() {
    const [open, setOpen] = React.useState(false),
        [loader, setLoader] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function onCreate(e) {
        e.preventDefault()
        setLoader(true)
        let formData = new FormData(e.target)
        let res = await resources.category.create(formData)
        if (res.status === 201) {
            setLoader(false)
            setOpen(false)
        } else {
            setLoader(false)
        }
        setLoader(false)
    }


    return (
        <div className="col">
            <div className="card">
                <div className="card-body">
                    <div className="d-flex justify-content-between mb-4">
                        <h6>Categories des dépenses</h6>
                        <button type="button" className="btn btn-app-default btn-sm" onClick={handleClickOpen}>Nouvelle
                            categorie
                        </button>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <form onSubmit={onCreate}>
                                <DialogTitle
                                    id="alert-dialog-title">{"Nouvelle catégorie"}</DialogTitle>
                                <DialogContent>
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Nom <span
                                            style={{color: "red"}}>*</span></label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1"
                                               name="name"/>
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <button onClick={handleClose} type="button" className="btn btn-danger">
                                        Annuler
                                    </button>
                                    <Btn onLoad={loader} type="submit" className="btn btn-secondary">
                                        Créer
                                    </Btn>
                                </DialogActions>
                            </form>
                        </Dialog>
                    </div>

                    <DataTable column={[
                        {label: "Nom", field: "name"}
                    ]}
                               element={""}
                               resource={resources.category}
                               action={() => {

                               }}
                               deleteItem/>
                </div>
            </div>

        </div>
    )
}