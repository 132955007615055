import Axios from "../Axios";

const estimation = {
    create(data) {
        return Axios.post('/estimation', data)
    },
    read(limit = 10, page = 1) {
        return Axios.get('/estimation?active=1&limit=' + limit + "&page=" + page)
    },
    readOne(id) {
        return Axios.get('/estimation/' + id)
    },
    update(id, data) {
        return Axios.put('/estimation/' + id, data)
    },
    delete(id) {
        return Axios.put('/estimation/' + id, {
            "active": false
        })
    },
}

export default estimation;
