import React, {useState, useEffect} from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import SaveIcon from '@material-ui/icons/Save';
import {Link, useParams} from "react-router-dom";
import InvoiceItem from "../../composents/InvoiceItem";
import AddIcon from '@material-ui/icons/Add';
import resources from "../../services";
import Btn from "../../ui/Btn";
import {useAlert} from "../../ui/Alert";
import Select from 'react-select'

function EditEstimation() {
    const [total, setTotal] = useState(0),
        [reduct, setReduct] = useState(0),
        [items, setItems] = useState([]),
        [article, setArticle] = useState([]),
        [ref, setRef] = useState(),
        [data, setData] = useState([]),
        [customers, setCustomers] = useState([]),
        [customer, setCustomer] = useState({}),
        [note, setNote] = useState(),
        [dueDate, setDueDate] = useState(),
        [local, setLocal] = useState(),{push} = useAlert(),
        {id} = useParams(),
        [loader, setLoader] = useState(false)

    async function onGet() {
        let est = await resources.estimation.readOne(id)
        if (est.status === 200) {
            setCustomer(est.data.customer)
            setData(est.data)
            setReduct(est.data.discount)
            setRef(est.data.ref)
            setItems(est.data.estimationItems)
            setNote(est.data.note)
            setDueDate(est.data.dueDate)

        }

        let pro = await resources.article.read()
        if (pro.status === 200) {
            let a = []
            pro.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                    price: el.price
                })
            })
            setArticle(a)
        }


        let cus = await resources.customer.read()
        if (cus.status === 200) {
            let a = []
            cus.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.fullName,
                })
            })
            setCustomers(a)
        }
    }

    useEffect(() => {
        onGet();
        setLocal(createDate());
    }, [])

    function createDate() {
        let date = new Date();

        let d = date.getDate();
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        let g = 0;
        let e = 0;

        if (m < 10) {
            g = "0" + m;
        } else {
            g = m;
        }
        if (d < 10) {
            e = "0" + d;
        } else {
            e = d;
        }
        let gD = y + "-" + g + "-" + e;
        return gD;
    }

    function onAddItem() {
        setItems(e => [...e, {
            item: '',
            price: 0,
            qty: 0,
            total: 0
        }])
    }

    function onDelete(e) {
        let a = []
        items.forEach((el, key) => {
            if (key != e) {
                a.push(el)
            }
        })
        setItems(a)
    }

    function handle(id, e) {
        const a = items;
        a.forEach((el, key) => {
            if (id == key) {
                el.item = e.item;
                el.price = e.price;
                el.qty = e.qty;
                el.total = e.total;
            }
        });
        setItems(a);
        let some = 0;
        for (let index = 0; index < a.length; index++) {
            some += a[index].total;
        }
        setTotal(some);


    }

    async function onCreate(e) {
        e.preventDefault()
        setLoader(true)
        let object = {
            customer: customer,
            total: total - (total * reduct) / 100,
            discount: reduct,
            dueDate: e.target.dueDate.value,
            items: items,
            ref: e.target.ref.value,
            note: e.target.note.value
        };
        let res = await resources.estimation.update(id, object)
        if (res.status === 201) {
            push({
                success: true
            })
       
        } else {
            push({
                error: true
            })
        }
        setLoader(false)
    }

    return (<div>
        <Header/>
        <div className="container-fluid">
            <div className="row">
                <Sidebar/>
                <form onSubmit={onCreate}>
                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                        <div>
                            <h3>Dévis {id}</h3>
                            <div className="d-flex justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link className="text-muted"
                                                                              to="/dashboard">Acceuil</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page"><Link
                                            className="text-muted"
                                            to="/estimations">Dévis</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Modifier dévis</li>
                                    </ol>
                                </nav>
                                <Btn className="btn btn-app-default btn-sm"
                                     type="submit" onLoad={loader}><SaveIcon/> Enregistré</Btn>
                            </div>
                        </div>
                        <div className="row  mt-3 mb-3">
                            <div className="col">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1"
                                                           className="form-label">Client <span
                                                        style={{color: "red"}}>*</span></label>
                                                    {
                                                        customer && (<Select onChange={(e) => setCustomer(e.value)}
                                                                             defaultValue={{
                                                                                 label: customer.fullName,
                                                                                 value: customer.id
                                                                             }} options={customers}/>)
                                                    }


                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label">Date
                                                        limite <span style={{color: "red"}}>*</span></label>
                                                    <input type="date" className="form-control" id="exampleInputEmail1"
                                                           name="dueDate" min={local} defaultValue={dueDate}/>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1"
                                                           className="form-label">Référence</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                                           name="ref" value={ref}
                                                           onChange={(e) => setRef(e.target.value)}/>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="mt-3">
                                    <div className="card card-body">
                                        <div className="row">
                                            <div className="col-lg-5"><h6>Article</h6></div>
                                            <div className="col-lg-2"><h6>Prix</h6></div>
                                            <div className="col-lg-2"><h6>Quantité</h6></div>
                                            <div className="col-lg-2"><h6>Total</h6></div>
                                            <div className="col-lg-1"><h6></h6></div>
                                        </div>
                                    </div>

                                    {
                                        items.map((el, key) => {
                                            return <InvoiceItem key={key} position={key} data={el} onDelete={onDelete}
                                                                onChange={handle} article={article}/>
                                        })
                                    }
                                    <button className="btn btn-light w-100 mt-3" type="button" onClick={onAddItem}>
                                        <AddIcon/> AJOUTER UNE LIGNE
                                    </button>

                                </div>
                            </div>
                        </div>


                        <div className=" card card-body">
                            <div className="row">
                                <div className="col">
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlTextarea1" className="form-label">Note</label>
                                        <textarea className="form-control" id="exampleFormControlTextarea1"
                                                  rows="3" name="note" defaultValue={note}></textarea>
                                    </div>
                                </div>
                                <div className="col">
                                    <table className="table">
                                        <tbody>
                                        <tr>
                                            <td colSpan="2">Sous-total</td>
                                            <td className="text-end"> {total}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">Remise(%)</td>
                                            <td><input type="text" className="form-control " value={reduct}
                                                       onChange={(e) => setReduct(e.target.value)}/></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" className="fw-bold"><h5>TOTAL</h5></td>
                                            <td className="text-end fw-bold"><h5>{total - (total * reduct) / 100}</h5>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </main>
                </form>
            </div>
        </div>

    </div>)
}

export default EditEstimation
