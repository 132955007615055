import React, {useState, useEffect} from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import AddIcon from '@material-ui/icons/Add';
import {Link} from "react-router-dom";
import resources from "../../services";
import DataTable from "../../composents/DataTable";

function CustomerPage() {

    return (<div>
        <Header/>
        <div className="container-fluid">
            <div className="row">
                <Sidebar/>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                    <div>
                        <h3>Clients</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link className="text-muted"
                                                                          to="/dashboard">Acceuil</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Clients</li>
                                </ol>
                            </nav>
                            <Link className="btn btn-app-default btn-sm" to="/customer/create"><AddIcon/> Nouveau
                                client</Link>
                        </div>
                    </div>

                    <div className="col mt-5">
                        <DataTable column={[
                            {label: "Nom", field: "fullName"},
                            {label: "Numéro de téléphone", field: "phone"},
                            {label: "Email", field: "email"},
                        ]}
                                   element={"customer"}
                                   resource={resources.customer}
                                   action={()=>{}}
                                   update
                                   deleteItem/>
                    </div>
                </main>
            </div>
        </div>

    </div>)
}

export default CustomerPage
