import React, {useState, useEffect} from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import AddIcon from '@material-ui/icons/Add';
import {Link} from "react-router-dom";
import resources from "../../services";
import Archive from "../../composents/Archive";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Btn from "../../ui/Btn";
import resource from "../../services";

function ArchivePage() {
    const [data, setData] = useState([]),
        [open, setOpen] = useState(false),
        [loader, setLoader] = useState(false),
        [limit, setLimit] = useState(10),
        [page, setPage] = useState(1),
        [pages, setPages] = useState([]),
        [detail, setDetail] = useState({}),
        [currentPage, setCurrentPage] = useState(1)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function onGet() {
        let res = await resource.archive.read(limit, page)
        if (res.status === 200) {
            setData(res.data.items)
            setDetail(res.data)
            setPages(res.data.pagesInRange)
            setCurrentPage(res.data.currentPageNumber)
        }
    }

    async function onCreate(e) {
        e.preventDefault()
        setLoader(true)
        let formData = new FormData(e.target)
        let res = await resources.archive.create(formData)
        if (res.status === 201) {
            onGet()
            setLoader(false)
            setOpen(false)
        } else {
            setLoader(false)
        }
        setLoader(false)
    }

    useEffect(() => {
        onGet();
    }, [limit, page])

    return (<div>
        <Header/>
        <div className="container-fluid">
            <div className="row">
                <Sidebar/>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                    <div>
                        <h3>Archives</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link className="text-muted"
                                                                          to="/dashboard">Acceuil</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Archives</li>
                                </ol>
                            </nav>
                            <button className="btn btn-app-default btn-sm" onClick={handleClickOpen}><AddIcon/> Nouveau
                                classeur
                            </button>

                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <form onSubmit={onCreate}>
                                    <DialogTitle
                                        id="alert-dialog-title">{"Nouveau classeur"}</DialogTitle>
                                    <DialogContent>
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Nom <span
                                                style={{color: "red"}}>*</span></label>
                                            <input type="text" className="form-control" id="exampleFormControlInput1"
                                                   name="name"/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlTextarea1"
                                                   className="form-label">Description</label>
                                            <textarea className="form-control" id="exampleFormControlTextarea1"
                                                      rows="3" name="note"></textarea>
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <button onClick={handleClose} type="button" className="btn btn-danger">
                                            Annuler
                                        </button>
                                        <Btn onLoad={loader} type="submit" className="btn btn-secondary">
                                            Créer
                                        </Btn>
                                    </DialogActions>
                                </form>
                            </Dialog>
                        </div>
                    </div>

                    <div className="row mt-5">
                        {
                            data && data.map((el, key) => {
                                return <Archive name={el.name} id={el.id} note={el.note} onGet={onGet}/>
                            })
                        }

                    </div>
                    <div className="d-flex justify-content-between">
                        <div>
                            <select className="form-select form-select-sm" aria-label="Default select example"
                                    onChange={(e) => setLimit(e.target.value)}>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end">
                                <li className={detail?.previous != null ? "page-item" : "page-item disabled"}>
                            <span className="page-link" onClick={() => setPage(detail?.previous)}><span
                                aria-hidden="true">&laquo;</span></span>
                                </li>
                                {
                                    pages && pages.map((el, key) => {
                                        return <li className={currentPage == el ? "page-item active" : "page-item"}
                                                   key={key}><a
                                            className="page-link"
                                            onClick={() => setPage(el)}>{el}</a>
                                        </li>
                                    })
                                }

                                <li className={detail?.next != null ? "page-item" : "page-item disabled"}>
                            <span className="page-link" onClick={() => setPage(detail?.next)}><span
                                aria-hidden="true">&raquo;</span></span>
                                </li>
                            </ul>
                        </nav>

                    </div>

                </main>
            </div>
        </div>

    </div>)
}

export default ArchivePage
