import React, {useState, useEffect} from "react"

export default function Card({value, name, icon}) {
    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col">
                        <h4 className="fw-bold">{value}</h4>
                        <h6 className="text-muted">{name}</h6>
                    </div>
                    <div className="col-lg-2 justify-content-center align-content-center align-items-center">
                        {icon}
                    </div>
                </div>
            </div>
        </div>
    )
}