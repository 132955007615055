
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CustomerPage from "./pages/Customer/CustomerPage";
import Dashboard from "./pages/Dashboard/Dashboard";
import CreateCustomer from "./pages/Customer/CreateCustomer";
import ArticlePage from "./pages/Article/ArticlePage";
import CreateArticle from "./pages/Article/CreateArticle";
import EstimationPage from "./pages/Estimation/EstimationPage";
import CreateEstimation from "./pages/Estimation/CreateEstimation";
import InvoicePage from "./pages/Invoice/InvoicePage";
import CreateInvoice from "./pages/Invoice/Createinvoice";
import PayementPage from "./pages/Payement/PayementPage";
import CreatePayment from "./pages/Payement/CreatePayment";
import ExpensePage from "./pages/Expense/ExpensePage";
import CreateExpense from "./pages/Expense/CreateExpense";
import UserPage from "./pages/User/UserPage";
import CreateUser from "./pages/User/CreateUser";
import ReportPage from "./pages/Report/ReportPage";
import SettingPage from "./pages/Setting/SettingPage";
import ArchivePage from "./pages/Archive/ArchivePage";
import ProjectPage from "./pages/Project/ProjectPage";
import { Alert } from "./ui/Alert";
import EditCustomer from "./pages/Customer/EditCustomer";
import EditArticle from "./pages/Article/EditArticle";
import EditUser from "./pages/User/EditUser";
import ViewArchive from "./pages/Archive/ViewArchive";
import LoginPage from "./pages/Login/LoginPage";
import CreateProject from "./pages/Project/CreateProject";
import ViewProject from "./pages/Project/ViewProject";
import ViewInvoice from "./pages/Invoice/ViewInvoice";
import EditEstimation from "./pages/Estimation/EditEstimation";
import EditInvoice from "./pages/Invoice/EditInvoice";
import OpportunitiePage from "./pages/Opportunitie/OpportunitiePage";
import CreateOpportunitie from "./pages/Opportunitie/CreateOpportunitie";
import EditOpportunitie from "./pages/Opportunitie/EditOpportunitie";
import ViewEstimation from './pages/Estimation/ViewEstimation';

function App() {
    return (
        <Router>

            <Alert>

                <Routes>

                    {/*Read*/}
                    <Route exact path="/" element={<LoginPage />} />
                    <Route exact path="/dashboard" element={<Dashboard />} />
                    <Route exact path="/customers" element={<CustomerPage />} />
                    <Route exact path="/articles" element={<ArticlePage />} />
                    <Route exact path="/opportunities" element={<OpportunitiePage />} />
                    <Route exact path="/estimations" element={<EstimationPage />} />
                    <Route exact path="/invoices" element={<InvoicePage />} />
                    <Route exact path="/payments" element={<PayementPage />} />
                    <Route exact path="/expenses" element={<ExpensePage />} />
                    <Route exact path="/archives" element={<ArchivePage />} />
                    <Route exact path="/projects" element={<ProjectPage />} />
                    <Route exact path="/users" element={<UserPage />} />
                    <Route exact path="/reports" element={<ReportPage />} />
                    <Route exact path="/settings/:action" element={<SettingPage />} />

                    {/*Create*/}
                    <Route exact path="/customer/create" element={<CreateCustomer />} />
                    <Route exact path="/article/create" element={<CreateArticle />} />
                    <Route exact path="/opportunitie/create" element={<CreateOpportunitie />} />
                    <Route exact path="/estimation/create" element={<CreateEstimation />} />
                    <Route exact path="/invoice/create" element={<CreateInvoice />} />
                    <Route exact path="/payment/create" element={<CreatePayment />} />
                    <Route exact path="/payment/create/:invoiceId/:rest/:customerId/:customerName"
                        element={<CreatePayment />} />
                    <Route exact path="/expense/create" element={<CreateExpense />} />
                    <Route exact path="/user/create" element={<CreateUser />} />
                    <Route exact path="/project/create" element={<CreateProject />} />


                    {/*Edit*/}
                    <Route exact path="/customer/edit/:id" element={<EditCustomer />} />
                    <Route exact path="/article/edit/:id" element={<EditArticle />} />
                    <Route exact path="/opportunitie/edit/:id" element={<EditOpportunitie />} />
                    <Route exact path="/estimation/edit/:id" element={<EditEstimation />} />
                    <Route exact path="/invoice/edit/:id" element={<EditInvoice />} />
                    <Route exact path="/payment/edit/:id" element={<CreatePayment />} />
                    <Route exact path="/expense/edit/:id" element={<CreateExpense />} />
                    <Route exact path="/user/edit/:id" element={<EditUser />} />

                    {/*View*/}
                    <Route exact path="/archive/view/:id" element={<ViewArchive />} />
                    <Route exact path="/project/view/:id" element={<ViewProject />} />
                    <Route exact path="/invoice/view/:id" element={<ViewInvoice />} />
                    <Route exact path="/estimation/view/:id" element={<ViewEstimation />} />
                </Routes>
            </Alert>
        </Router>
    )
}

export default App
