import React, {useState, useEffect, useRef} from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import PrintIcon from '@material-ui/icons/Print';
import {Link} from "react-router-dom";
import Sale from "./Sale";
import Btn from "../../ui/Btn";
import resources from "../../services";
import moment from "moment"
import Expense from "./Expense";
import ReactToPrint from "react-to-print";

function ReportPage() {
    const [sales, setSales] = useState([]), [expenses, setExpenses] = useState([]),
        [loader, setLoader] = useState(false),
        [from, setFrom] = useState("Début"),
        [to, setTo] = useState(moment().format("DD/MM/YYYY")),
        componentRef = useRef();

    function format_date(value) {
        return moment(value).format("DD-MM-YYYY");
    }

    async function onGenereSales(e) {
        e.preventDefault()
        let formData = new FormData()
        setLoader(true)

        if (e.target.from.value) {
            setFrom(e.target.from.value);
            formData.append("from", format_date(e.target.from.value));
        }
        if (e.target.to.value) {
            setTo(e.target.to.value);
            formData.append("to", format_date(e.target.to.value));
        }

        let res = await resources.report.payment(formData)
        if (res.status === 200) {
            setSales(res.data)
            setLoader(false)
        }
        setLoader(false)
    }


    async function onGenereExpenses(e) {
        e.preventDefault()
        let formData = new FormData()
        setLoader(true)

        if (e.target.from.value) {
            setFrom(e.target.from.value);
            formData.append("from", format_date(e.target.from.value));
        }
        if (e.target.to.value) {
            setTo(e.target.to.value);
            formData.append("to", format_date(e.target.to.value));
        }

        let res = await resources.report.expense(formData)
        if (res.status === 200) {
            setExpenses(res.data)
            setLoader(false)
        }
        setLoader(false)
    }

    return (<div>
        <Header/>
        <div className="container-fluid">
            <div className="row">
                <Sidebar/>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                    <div>
                        <h3>Rapports</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link className="text-muted"
                                                                          to="/dashboard">Acceuil</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Rapports</li>
                                </ol>
                            </nav>

                        </div>
                    </div>


                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                                    aria-selected="true">Rapport de vente
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-profile" type="button" role="tab"
                                    aria-controls="pills-profile" aria-selected="false">des dépenses
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                             aria-labelledby="pills-home-tab">
                            <div className="row">
                                <div className="col-lg-3">

                                    <form onSubmit={onGenereSales}>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Date</label>
                                            <input type="date" className="form-control" id="exampleInputEmail1"
                                                   name="from"/>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Date</label>
                                            <input type="date" className="form-control" id="exampleInputEmail1"
                                                   name="to"/>
                                        </div>
                                        <Btn onLoad={loader} className="btn btn-app-default w-100 mt-3" type="submit">
                                            Generer
                                        </Btn>
                                    </form>


                                </div>
                                <div className="col-lg-9">
                                    {
                                        sales.length > 0 && <Sale data={sales} from={from} to={to}/>
                                    }

                                </div>

                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel"
                             aria-labelledby="pills-profile-tab">
                            <div className="row">
                                <div className="col-lg-3">

                                    <form onSubmit={onGenereExpenses}>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Date</label>
                                            <input type="date" className="form-control" id="exampleInputEmail1"
                                                   name="from"/>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Date</label>
                                            <input type="date" className="form-control" id="exampleInputEmail1"
                                                   name="to"/>
                                        </div>
                                        <Btn onLoad={loader} className="btn btn-app-default w-100 mt-3" type="submit">
                                            Generer
                                        </Btn>
                                    </form>


                                </div>
                                <div className="col-lg-9">
                                    {
                                        expenses.length > 0 && <Expense data={expenses} from={from} to={to}/>
                                    }

                                </div>

                            </div>
                        </div>
                    </div>


                </main>
            </div>
        </div>

    </div>)
}

export default ReportPage
