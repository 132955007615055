import React, {useState, useEffect} from 'react'
import CreatableSelect from "react-select/creatable";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

function InvoiceItem(props) {
    const {position, onDelete, data, onChange, article} = props

    const [item, setItem] = useState(data.item);
    const [quantity, setQuantity] = useState(data.qty);
    const [price, setPrice] = useState(data.price);
    const [total, setTotal] = useState(data.total);

    useEffect(() => {
        setTotal(quantity * price);
        onChange(position, {
            item: item,
            price: price,
            qty: quantity,
            total: total,
        });
    }, [quantity, price, item, total]);

    function onSelectProduct(e) {

        if (e.__isNew__) {
            setItem(e.label)
            setPrice(0)
            setTotal(0);
        } else {
            setItem(e.label)
            setPrice(e.price)
            setTotal(e.price * quantity);
            console.log(e.price)
        }
    }

    function onChangeQty(e) {
        setQuantity(e);
        setTotal(e * price);
    }

    function onChangePrice(e) {
        setPrice(e);
        setTotal(e * quantity);
    }

    return (<div className="card card-body mt-2 ">
        <div className="row ">
            <div className="col-lg-5">
                <CreatableSelect isClearable defaultValue={{
                    label: item
                }} onChange={(e) => onSelectProduct(e)} options={article}/>
            </div>
            <div className="col-lg-2">
                <input type="text"
                       className="form-control"
                       onChange={(e) => onChangePrice(e.target.value)}
                       value={price}/>
            </div>
            <div className="col-lg-2">
                <input type="text"
                       className="form-control"
                       onChange={(e) => onChangeQty(e.target.value)}
                       value={quantity}/>
            </div>
            <div className="col-lg-2">
                <input type="text"
                       className="form-control"
                       readOnly
                       value={total}/>
            </div>
            <div className="col-lg-1 align-content-center align-items-center justify-content-center">
                {
                    !position == 0 && <DeleteOutlineIcon onClick={() => onDelete(position)}/>
                }
            </div>
        </div>
    </div>)
}

export default InvoiceItem
