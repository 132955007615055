import React, {useState, useEffect} from "react"
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import resources from "../services";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import {Link} from "react-router-dom";

export default function Project({data, key, onGet}) {
    const [open, setOpen] = React.useState(false);
    const [loader, setLoader] = React.useState(false);
    const [progress, setProgress] = React.useState(12);
    const [completed, setCompleted] = useState(0)
    const [uncompleted, setUnCompleted] = useState(0)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function onUpdate(e) {
        e.preventDefault()
        setLoader(true)
        // let objet = {
        //     name: e.target.name.value,
        //     note: e.target.note.value
        // }
        // let res = await resources.project.update(id, objet)
        // if (res.status === 201) {
        //     onGet()
        //     setLoader(false)
        //     setOpen(false)
        // } else {
        //     setLoader(false)
        // }
        // setLoader(false)
    }

    async function onDelete() {
        let res = await resources.project.delete(data.id)
        if (res.status === 201) {
            onGet()

        }
    }

    function onProgress() {
        let task = data.tasks.length
        let completed = data.tasks.filter(d => d.status === 1).length;
        let a = (completed * 100) / task
        setProgress(a)
        setCompleted(completed)
        setUnCompleted(task - completed)

    }

    useEffect(() => {
        onProgress()
    }, [data.tasks])

    return (
        <div className="col-lg-3 mb-3" key={key}>
            <div className="card">
                <div className="card-body">
                    <div className="d-flex justify-content-between">
                        <h5 className="fw-bold">{data?.name}</h5>
                        <div className="dropdown">
                            <MoreVertIcon id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                          aria-expanded="false" style={{cursor: "pointer"}}/>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><Link className="dropdown-item" to={"/project/view/" + data.id}>
                                    <AssignmentTurnedInIcon/> Taches</Link>
                                </li>
                                <li><span className="dropdown-item">
                                        <EditIcon/> Modifier</span>
                                </li>
                                <li><span className="dropdown-item" onClick={onDelete}>
                                        <DeleteIcon/> Supprimer</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p className="fw-bold"> {uncompleted} <small className="text-muted">tache en cours</small></p>
                        <p className="fw-bold"> {completed } <small className="text-muted"> tache terminée</small></p>

                    </div>
                    <p className="text-muted">
                        {data?.note}
                    </p>
                    <p className="fw-bold">Date limite :</p>
                    <p className="text-muted mb-4">{data?.endDate}</p>

                    <p className="fw-bold">Chef de projet :</p>
                    {
                        data && data?.lead && (
                            <span className="badge  bg-light text-dark me-1">{data?.lead?.fullName}</span>)
                    }
                    <p className="fw-bold mt-3">Equipe :</p>
                    {
                        data && data?.team.map((el, key) => {
                            return <span className="badge  bg-light text-dark me-1">{el.fullName}</span>
                        })
                    }
                    <div className="d-flex justify-content-between mt-3">
                        <p className="fw-bold">Progres </p>
                        <p className="text-success">{progress}% </p>
                    </div>

                    <div className="progress">
                        <div className="progress-bar bg-success"
                             role="progressbar"
                             style={{width: progress + "%"}}
                             aria-valuenow={progress}
                             aria-valuemin="0"
                             aria-valuemax="100"/>
                    </div>

                </div>
            </div>

        </div>
    )
}