import React, {useState, useEffect} from 'react'
import {Link} from "react-router-dom";
import DescriptionIcon from "@material-ui/icons/Description";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonIcon from '@material-ui/icons/Person';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import PaymentIcon from '@material-ui/icons/Payment';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PieChartIcon from '@material-ui/icons/PieChart';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ArchiveIcon from '@material-ui/icons/Archive';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';

function Sidebar() {
    return (<nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-white sidebar collapse">
        <div className="position-sticky pt-3 mb-3">
            <ul className="nav flex-column ">
                <li className="nav-item ">
                    <Link className="nav-link " to="/dashboard">
                        <div className="d-flex">
                            <DashboardIcon className="text-muted"/>
                            <p className="ms-3 fw-bolder">Dashboard</p>
                        </div>
                    </Link>
                </li>
                <li className="nav-item ">
                    <Link className="nav-link" to="/customers">
                        <div className="d-flex">
                            <PersonIcon className="text-muted"/>
                            <p className="ms-3 fw-bolder">Clients</p>
                        </div>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/articles">
                        <div className="d-flex">
                            <StarBorderIcon className="text-muted"/>
                            <p className="ms-3 fw-bolder">Articles</p>
                        </div>
                    </Link>
                </li>
                <div className="mt-3"/>
                <li className="nav-item">
                    <Link className="nav-link" to="/opportunities">
                        <div className="d-flex">
                            <AddToQueueIcon className="text-muted"/>
                            <p className="ms-3 fw-bolder">Opportunités</p>
                        </div>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className="nav-link" to="/estimations">
                        <div className="d-flex">
                            <DescriptionIcon className="text-muted"/>
                            <p className="ms-3 fw-bolder">Estimations</p>
                        </div>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/invoices">
                        <div className="d-flex">
                            <InsertDriveFileIcon className="text-muted"/>
                            <p className="ms-3 fw-bolder">Factures</p>
                        </div>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/payments">
                        <div className="d-flex">
                            <PaymentIcon className="text-muted"/>
                            <p className="ms-3 fw-bolder">Paiements</p>
                        </div>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/expenses">
                        <div className="d-flex">
                            <PhoneAndroidIcon className="text-muted"/>
                            <p className="ms-3 fw-bolder">Dépenses</p>
                        </div>
                    </Link>
                </li>

                <div className="mt-3"/>
                <li className="nav-item">
                    <Link className="nav-link" to="/archives">
                        <div className="d-flex">
                            <ArchiveIcon className="text-muted"/>
                            <p className="ms-3 fw-bolder">Archives</p>
                        </div>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/projects">
                        <div className="d-flex">
                            <AccountTreeIcon className="text-muted"/>
                            <p className="ms-3 fw-bolder">Projets</p>
                        </div>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/users">
                        <div className="d-flex">
                            <PeopleAltIcon className="text-muted"/>
                            <p className="ms-3 fw-bolder">Utilisateurs</p>
                        </div>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/reports">
                        <div className="d-flex">
                            <PieChartIcon className="text-muted"/>
                            <p className="ms-3 fw-bolder">Rapports</p>
                        </div>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className="nav-link" to="/settings/category">
                        <div className="d-flex">
                            <SettingsIcon className="text-muted"/>
                            <p className="ms-3 fw-bolder">Réglages</p>
                        </div>
                    </Link>
                </li>

            </ul>

        </div>
    </nav>)
}

export default Sidebar
