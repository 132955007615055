import React, {useState, useEffect} from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import Card from "../../composents/Card";
import PeopleIcon from '@material-ui/icons/People';
import DescriptionIcon from '@material-ui/icons/Description';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import resources from "../../services";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip
} from "recharts";
import EqualizerIcon from '@material-ui/icons/Equalizer';


function createData(time, vente, depense, client) {
    return {time, vente, depense, client};
}

const data = [
    createData("Janvier", 0, 3, 4),
    createData("Fevrier", 300, 6, 9),
    createData("Mars", 600, 900, 0),
    createData("Avril", 800, 0, 9),
    createData("Mais", 1500, 76, 8),
    createData("juin", 2000, 98, 4),
    createData("Juillet", 2400, 364, 20),
    createData("Aout", 2400, 467, 68),
    createData("Septembre", undefined, undefined, 900),
];


function Dashboard() {

    const [customers, setCustomers] = useState(0),
        [invoices, setInvoices] = useState(0),
        [estimations, setEstimations] = useState(0),
        [chart, setChart] = useState([]),
        [period, setPeriod] = useState('week')

    async function onGet() {
        let cust = await resources.customer.read()
        if (cust.status === 200) {
            setCustomers(cust.data.totalCount)
        }

        let inv = await resources.invoice.read()
        if (inv.status === 200) {
            setInvoices(inv.data.totalCount)
        }

        let est = await resources.estimation.read()
        if (est.status === 200) {
            setEstimations(est.data.totalCount)
        }

        let formData = new FormData()
        formData.append("period", period)
        let chart = await resources.chart.create(formData)
        if (chart.status === 200) {
            setChart(chart.data)
        }
    }

    useEffect(() => {
        onGet()
    }, [period])


    return (<div>
        <Header/>
        <div className="container-fluid">
            <div className="row">
                <Sidebar/>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="row mt-4">
                        <div className="col">
                            <Card value={customers} name="Clients"
                                  icon={<PeopleIcon style={{fontSize: 40, color: "blue"}}/>}/>
                        </div>
                        <div className="col">
                            <Card value={invoices} name="Factures"
                                  icon={<DescriptionIcon style={{fontSize: 40, color: "tomato"}}/>}/>
                        </div>
                        <div className="col">
                            <Card value={estimations} name="Estimations"
                                  icon={<FileCopyIcon style={{fontSize: 40, color: "green"}}/>}/>
                        </div>

                    </div>

                    <div className="row mt-4">
                        <div className="col">
                            <div className="card">
                                <div className="d-flex justify-content-between m-3">
                                    <h6 className="fw-bold"><EqualizerIcon/> Ventes, Dépenses & Clients</h6>
                                    <div>
                                        <select className="form-select" onChange={(e) => setPeriod(e.target.value)}>
                                            <option value="week">Semaine</option>
                                            <option value="month">Mois</option>
                                        </select>
                                    </div>

                                </div>
                                <div className="card-body" style={{
                                    height: 440,
                                    width: "100%"
                                }}>
                                    <ResponsiveContainer>
                                        <LineChart
                                            data={chart}
                                            margin={{
                                                top: 16,
                                                right: 16,
                                                bottom: 0,
                                                left: 24,
                                            }}
                                        >


                                            <XAxis dataKey="time" stroke={"black"}/>
                                            <YAxis stroke={"black"}/>
                                            <CartesianGrid strokeDasharray="3 3"/>
                                            <Line
                                                type="monotone"
                                                dataKey="Vente"
                                                stroke={"tomato"}

                                            />

                                            <Line
                                                type="monotone"
                                                dataKey="Dépense"
                                                stroke={"red"}

                                            />
                                            <Line
                                                type="monotone"
                                                dataKey="Client"
                                                stroke={"green"}

                                            />

                                            <Tooltip/>
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </div>
        </div>

    </div>)
}

export default Dashboard
