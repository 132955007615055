import React, {useState, useEffect} from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import {Link, useParams} from "react-router-dom";
import resources from "../../services";
import Btn from "../../ui/Btn";

function ViewProject() {
    const [data, setData] = useState([]),
        [loader, setLoader] = React.useState(false),
        {id} = useParams(),
        [addTask, setAddTask] = useState(false)


    async function onGet() {
        let res = await resources.project.readTask(id)
        if (res.status === 200) setData(res.data.items)
    }

    async function completed(value) {
        let res = await resources.project.updateTask(value, {
            status: 1
        })
        if (res.status === 201) {
            onGet()
        }
    }

    async function onCreate(e) {
        e.preventDefault()
        setLoader(true)
        let formData = new FormData(e.target)
        formData.append("project", id)
        let res = await resources.project.createTask(formData)
        if (res.status === 201) {
            onGet()
            setLoader(false)
        } else {
            setLoader(false)
        }
        setLoader(false)
    }

    useEffect(() => {
        onGet();
    }, [])

    return (<div>
        <Header/>
        <div className="container-fluid">
            <div className="row">
                <Sidebar/>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                    <div>
                        <h3>Taches du projet {id}</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link className="text-muted"
                                                                          to="/dashboard">Acceuil</Link></li>
                                    <li className="breadcrumb-item"><Link className="text-muted"
                                                                          to="/projects">Projets</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Projet {id}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <ul className="list-group">
                            {
                                data && data.map((el, key) => {
                                    return <li className="list-group-item" key={key}>
                                        <input className="form-check-input me-1" type="checkbox"
                                               defaultChecked={el.status === 0 ? false : true}
                                               onChange={() => completed(el.id)}/>
                                        {el.name}
                                    </li>
                                })
                            }

                            {
                                addTask ? (
                                    <li className="list-group-item">
                                        <form className="row " onSubmit={onCreate}>
                                            <div className="col">
                                                <label htmlFor="inputPassword2"
                                                       className="visually-hidden">Password</label>
                                                <input type="text" className="form-control" id="inputPassword2"
                                                       placeholder="Nom de la date" required name="name"/>
                                            </div>
                                            <div className="col-auto">
                                                <label htmlFor="inputPassword2"
                                                       className="visually-hidden">Password</label>
                                                <input type="date" className="form-control" id="inputPassword2"
                                                       placeholder="Date fin (optionnelle)" name="endDate"/>
                                            </div>
                                            <div className="col-auto">
                                                <Btn onLoad={loader} type="submit" className="btn btn-app-default ">
                                                    Enregistrer
                                                </Btn>
                                            </div>
                                            <div className="col-auto">
                                                <button type="button" className="btn btn-danger"
                                                        onClick={() => setAddTask(false)}>
                                                    Annuler
                                                </button>
                                            </div>
                                        </form>
                                    </li>
                                ) : (
                                    <li className="list-group-item" style={{cursor: "pointer"}}
                                        onClick={() => setAddTask(true)}>
                                        <p className="text-center text-success fw-bold">Nouvelle tache</p>
                                    </li>
                                )
                            }

                        </ul>
                    </div>

                </main>
            </div>
        </div>

    </div>)
}

export default ViewProject
