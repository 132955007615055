import React, {useState, useEffect} from 'react'
import Sidebar from "../../composents/Sidebar";
import Header from "../../composents/Header";
import AddIcon from '@material-ui/icons/Add';
import {Link} from "react-router-dom";
import resources from "../../services";
import Project from "../../composents/Project";

function ProjectPage() {
    const [data, setData] = useState([]),
        [open, setOpen] = React.useState(false),
        [loader, setLoader] = React.useState(false),
        [limit, setLimit] = useState(10),
        [page, setPage] = useState(1),
        [pages, setPages] = useState([]),
        [detail, setDetail] = useState({}),
        [currentPage, setCurrentPage] = useState(1)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function onGet() {
        let res = await resources.project.read(limit, page)
        if (res.status === 200) {
            setData(res.data.items)
            setDetail(res.data)
            setPages(res.data.pagesInRange)
            setCurrentPage(res.data.currentPageNumber)
        }
    }

    async function onCreate(e) {
        e.preventDefault()
        setLoader(true)
        let formData = new FormData(e.target)
        let res = await resources.archive.create(formData)
        if (res.status === 201) {
            onGet()
            setLoader(false)
            setOpen(false)
        } else {
            setLoader(false)
        }
        setLoader(false)
    }

    useEffect(() => {
        onGet();
    }, [limit, page])

    return (<div>
        <Header/>
        <div className="container-fluid">
            <div className="row">
                <Sidebar/>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                    <div>
                        <h3>Projets</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link className="text-muted"
                                                                          to="/dashboard">Acceuil</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Projets</li>
                                </ol>
                            </nav>
                            <Link className="btn btn-app-default btn-sm" to="/project/create"><AddIcon/> Nouveau
                                projet</Link>
                        </div>
                    </div>
                    <div className="row mt-5">
                        {
                            data && data.map((el, key) => {
                                return <Project data={el} key={key} onGet={onGet}/>
                            })
                        }
                    </div>
                    <div className="d-flex justify-content-between">
                        <div>
                            <select className="form-select form-select-sm" aria-label="Default select example"
                                    onChange={(e) => setLimit(e.target.value)}>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end">
                                <li className={detail?.previous != null ? "page-item" : "page-item disabled"}>
                            <span className="page-link" onClick={() => setPage(detail?.previous)}><span
                                aria-hidden="true">&laquo;</span></span>
                                </li>
                                {
                                    pages && pages.map((el, key) => {
                                        return <li className={currentPage == el ? "page-item active" : "page-item"}
                                                   key={key}><a
                                            className="page-link"
                                            onClick={() => setPage(el)}>{el}</a>
                                        </li>
                                    })
                                }

                                <li className={detail?.next != null ? "page-item" : "page-item disabled"}>
                            <span className="page-link" onClick={() => setPage(detail?.next)}><span
                                aria-hidden="true">&raquo;</span></span>
                                </li>
                            </ul>
                        </nav>

                    </div>

                </main>
            </div>
        </div>

    </div>)
}

export default ProjectPage
